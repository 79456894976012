@font-face {
	font-family: "TIM Sans";
	src: 
					url("./../fonts/TIM-Sans/TIM-Sans.woff2") format("woff2"),
					url("./../fonts/TIM-Sans/TIM-Sans.woff") format("woff");
	font-display: swap;
	font-weight: normal;
}

@font-face {
	font-family: "TIM Sans";
	src: 
					url("./../fonts/TIM-Sans-Bold/TIM-Sans-Bold.woff2") format("woff2"),
					url("./../fonts/TIM-Sans-Bold/TIM-Sans-Bold.woff") format("woff");
	font-display: swap;
	font-weight: bold;
}

body {
  font-family: "TIM Sans";
}