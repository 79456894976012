
body {
  margin: 0;
}

.marker-cluster {
  background:rgba(1, 138, 190, 0.6);
  text-align: center;
  border-radius:50%;
}

.marker-cluster div {
  margin: 5px;
  width: calc(100% - 10px);
  height: calc(100% - 10px);
  border-radius: 50%;
  background:#018ABE;
  display: block;
}

.marker-cluster div span {
  display: block;
  text-align: center;
  padding-top: 5px;
  color: #ffffff;
}

.poiSelected .marker-cluster {
  background:rgba(165, 165, 166, 0.6);
}

.poiSelected .marker-cluster div {
  background:#A5A5A6;
}
