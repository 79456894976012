.xeoglBox {
  border: 1px solid #00000029;
  // height: calc(100vh - 150px);
  width: 100%;
  height: 75vh;

  #xeoglCanvas {
    width: 100%;
    height: 100%;
  }
}

.preview__container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.preview__left {
  margin-top: 1rem;
  align-self: flex-start;
  min-width: 300px;
}
