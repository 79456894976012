.rect {
  background-color: transparent;
  border: none;
}

.arrow {
  width: 40px;
  height: 160px;
  background-image: url("../../assets/img/arrow.png") !important;
  background-repeat: no-repeat;
  background-size: contain;
  position: absolute;
}
