.arrow-wrapper {
  background-color: transparent;
  border: none;
}

.filter-arrow {
  width: 32px;
  height: 128px;
  background-image: url("../../assets/img/arrow.svg") !important;
  background-repeat: no-repeat;
  background-size: contain;
  position: absolute;
}
